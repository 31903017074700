import React from "react";
import "./kaia.scss";
import Slider from "react-slick";
import Kaia8 from "../../../media/kaia/kaia8.js";
import Kaia10 from "../../../media/kaia/kaia10.js";
import Kaia12 from "../../../media/kaia/kaia12.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faBed,
  faShower,
  faMaximize,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const kaia = () => {
  const kaia8 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Kaia8)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  const kaia10 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Kaia10)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  const kaia12 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Kaia12)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div id="kaia" className="container-kaia">
      <div className="card">
        <Slider {...settings}>
          {Kaia8.map((kaia8, index) => (
            <img
              className="card-image"
              key={index}
              src={kaia8}
              alt={`Launching ${index + 1}`}
            ></img>
          ))}
        </Slider>
        <div className="content-card">
          <div className="card-title">KAIA 8</div>
          <div className="card-price">
            <div className="judul-pt">START FROM</div>
            <div className="pricetotal">15</div>
            <div className="ket-pt">JUTA/BULAN</div>
          </div>
          <div className="card-dp">TANPA DP</div>
          <div className="card-icon">
            <FontAwesomeIcon icon={faMaximize} />
            <span>: 128m² </span>
            <FontAwesomeIcon icon={faHouse} />
            <span>: 182m² </span>
            <FontAwesomeIcon icon={faBed} />
            <span>: 5+1 </span>
            <FontAwesomeIcon icon={faShower} />
            <span>: 5+1 </span>
          </div>
          <div className="card-button">
            <button className="button" onClick={kaia8}>
              <FontAwesomeIcon size="lg" icon={faWhatsapp} />
              &nbsp;Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div className="card">
        <Slider {...settings}>
          {Kaia10.map((kaia10, index) => (
            <img
              className="card-image"
              key={index}
              src={kaia10}
              alt={`Launching ${index + 1}`}
            ></img>
          ))}
        </Slider>
        <div className="content-card">
          <div className="card-title">KAIA 10</div>
          <div className="card-price">
            <div className="judul-pt">START FROM</div>
            <div className="pricetotal">22</div>
            <div className="ket-pt">JUTA/BULAN</div>
          </div>
          <div className="card-dp">TANPA DP</div>
          <div className="card-icon">
            <FontAwesomeIcon icon={faMaximize} />
            <span>: 180m² </span>
            <FontAwesomeIcon icon={faHouse} />
            <span>: 260m² </span>
            <FontAwesomeIcon icon={faBed} />
            <span>: 5+1 </span>
            <FontAwesomeIcon icon={faShower} />
            <span>: 5+1 </span>
          </div>
          <div className="card-button">
            <button className="button" onClick={kaia10}>
              <FontAwesomeIcon size="lg" icon={faWhatsapp} />
              &nbsp;Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div className="card">
        <Slider {...settings}>
          {Kaia12.map((kaia12, index) => (
            <img
              className="card-image"
              key={index}
              src={kaia12}
              alt={`Launching ${index + 1}`}
            ></img>
          ))}
        </Slider>
        <div className="content-card">
          <div className="card-title">KAIA 12</div>
          <div className="card-price">
            <div className="judul-pt">START FROM</div>
            <div className="pricetotal">30</div>
            <div className="ket-pt">JUTA/BULAN</div>
          </div>
          <div className="card-dp">TANPA DP</div>
          <div className="card-icon">
            <FontAwesomeIcon icon={faMaximize} />
            <span>: 240m² </span>
            <FontAwesomeIcon icon={faHouse} />
            <span>: 328m² </span>
            <FontAwesomeIcon icon={faBed} />
            <span>: 4+1 </span>
            <FontAwesomeIcon icon={faShower} />
            <span>: 5+1 </span>
          </div>
          <div className="card-button">
            <button className="button" onClick={kaia12}>
              <FontAwesomeIcon size="lg" icon={faWhatsapp} />
              &nbsp;Whatsapp
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default kaia;
