import Vicente101 from "./vicente10 (1).webp";
import Vicente102 from "./vicente10 (2).webp";
import Vicente103 from "./vicente10 (3).webp";
import Vicente104 from "./vicente10 (4).webp";
import Vicente105 from "./vicente10 (5).webp";
import Vicente106 from "./vicente10 (6).webp";
import Vicente107 from "./vicente10 (7).webp";
import Vicente108 from "./vicente10 (8).webp";
import Vicente109 from "./vicente10 (9).webp";
import Vicente1010 from "./vicente10 (10).webp";
import Vicente1011 from "./vicente10 (11).webp";

const vicente10 = [
  Vicente101,
  Vicente102,
  Vicente103,
  Vicente104,
  Vicente105,
  Vicente106,
  Vicente107,
  Vicente108,
  Vicente109,
  Vicente1010,
  Vicente1011,
];

export default vicente10;
