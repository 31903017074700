import Vicente71 from "./vicente7 (1).webp";
import Vicente72 from "./vicente7 (2).webp";
import Vicente73 from "./vicente7 (3).webp";
import Vicente74 from "./vicente7 (4).webp";
import Vicente75 from "./vicente7 (5).webp";
import Vicente76 from "./vicente7 (6).webp";
import Vicente77 from "./vicente7 (7).webp";
import Vicente78 from "./vicente7 (8).webp";
import Vicente79 from "./vicente7 (9).webp";
import Vicente710 from "./vicente7 (10).webp";
import Vicente711 from "./vicente7 (11).webp";
import Vicente712 from "./vicente7 (12).webp";
import Vicente713 from "./vicente7 (13).webp";
import Vicente714 from "./vicente7 (14).webp";

const vicente7 = [
  Vicente71,
  Vicente72,
  Vicente73,
  Vicente74,
  Vicente75,
  Vicente76,
  Vicente77,
  Vicente78,
  Vicente79,
  Vicente710,
  Vicente711,
  Vicente712,
  Vicente713,
  Vicente714,
];

export default vicente7;
