import React from "react";
import "./kavling.scss";
import aquaticimage from "../../media/kavling/aquatic.webp";
import deorangimage from "../../media/kavling/deorange.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const kavling = () => {
  const aquatic = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20kavling%20ini%20(Aquatic)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  const deorange = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20kavling%20ini%20(De'orange)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="container-kavling">
      <div className="judul1-kav">New Offer</div>
      <div className="judul2-kav">Kavling Residential Di Dalam Cluster</div>
      <div id="kavling" className="container-cluster-card">
        <div className="cluster-card">
          <img className="cluster-image" src={aquaticimage} alt={kavling} />
          <div className="cluster-card-title">
            <div className="cluster-name">
              <div className="cluster-name-sub">Aquatic</div>
              <div className="cluster-name-sub2">
                Harga Start from 5 Juta/Meter
              </div>
            </div>
          </div>
          <hr className="card-separator" />
          <div className="cluster-icon">
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
            <span>&nbsp;Luas Tanah : 240m² - 286m²&nbsp;&nbsp;</span>
          </div>
          <div className="cluster-button">
            <button onClick={aquatic} className="cluster-whatsapp">
              <FontAwesomeIcon size="lg" icon={faWhatsapp} />
              &nbsp;Whatsapp
            </button>
          </div>
        </div>
        <div className="cluster-card">
          <img className="cluster-image" src={deorangimage} alt={kavling} />
          <div className="cluster-card-title">
            <div className="cluster-name">
              <div className="cluster-name-sub">Grand Lemonade</div>
              <div className="cluster-name-sub2">
                Harga Start from 5 Juta/Meter
              </div>
            </div>
          </div>
          <hr className="card-separator" />
          <div className="cluster-icon">
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
            <span>&nbsp; Luas Tanah : 201m² - 358m²&nbsp;&nbsp;</span>
          </div>
          <div className="cluster-button">
            <button onClick={deorange} className="cluster-whatsapp">
              <FontAwesomeIcon size="lg" icon={faWhatsapp} />
              &nbsp;Whatsapp
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default kavling;
