import lt71 from "./s4.webp";
import lt72 from "./2-4.webp";
import lt73 from "./4-3.webp";
import lt74 from "./5-3.webp";
import lt75 from "./6-2.webp";
import lt76 from "./7-2.webp";
import lt77 from "./8-2.webp";
import lt78 from "./9-4.webp";
import lt79 from "./10-4.webp";
import lt710 from "./12-2.webp";
import lt711 from "./13-2.webp";

const lt7 = [
  lt71,
  lt72,
  lt73,
  lt74,
  lt75,
  lt76,
  lt77,
  lt78,
  lt79,
  lt710,
  lt711,
];

export default lt7;
