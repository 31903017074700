import t61 from './t6-1.webp'
import t62 from './t6-2.webp'
import t63 from './t6-3.webp'
import t64 from './t6-4.webp'
import t65 from './t6-5.webp'
import t66 from './t6-6.webp'
import t67 from './t6-7.webp'
import t68 from './t6-8.webp'
import t69 from './t6-9.webp'
import t610 from './t6-10.webp'

const lt6 = [t61, t62, t63, t64, t65,t66, t67, t68,t69, t610]

export default lt6;