import React from "react";
import "./comingsoon.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const comingsoon = () => {
  const wacs = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(vicente)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="comingsoon">
      <div className="container-cs"></div>
      <button className="buttonpenawaran" onClick={wacs}>
        <FontAwesomeIcon icon={faWhatsapp} /> Get More Info
      </button>
    </div>
  );
};

export default comingsoon;
