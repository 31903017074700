import w1 from "./s1.webp";
import w2 from "./1-4.webp";
import w3 from "./3-3.webp";
import w4 from "./4-2.webp";
import w5 from "./6-2-1.webp";
import w6 from "./7-2-1.webp";
import w7 from "./8-2-1.webp";
import w8 from "./9-2.webp";
import w9 from "./11-2.webp";

const water = [w1, w2, w3, w4, w5, w6, w7, w8, w9];

export default water;
