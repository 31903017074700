import React from "react";
import Grandwiss from "../../section/grandwis-carousel/Grandwiss";
import Grandjudul from "../../section/grandjudul/Grandjudul";
import Promo from "../../section/promo/Promo";
import Launching from "../../section/launching/Launching";
import "./Home.scss";
import Cluster from "../../section/cluster/Cluster";
import Commercial from "../../section/commercial/Commercial";
import Lokasi from "../../section/lokasi/Lokasi";
import Footer from "../../section/footer/Footer";
import Kavling from "../../section/kavling/kavling";
import ScrollToHashElement from "./ScrollToHashElement.js";
import Navbar from "../../section/Navbar/Navbar.jsx";
import Comingsoon from "../../section/comingsoon/comingsoon.jsx";

const Home = () => {
  return (
    <div className="home">
      <ScrollToHashElement />
      <Navbar />
      <Grandwiss />
      <Grandjudul />
      <Comingsoon />
      <Promo />
      <Launching />
      <Cluster />
      <Kavling />
      <Commercial />
      <Lokasi />
      <Footer />
    </div>
  );
};

export default Home;
