import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

const ScrollToHashElement = () => {
  const location = useLocation();

  useEffect(() => {
    const { hash } = location;

    const removeHashCharacter = (str) => {
      return str.slice(1);
    };

    const scrollToElement = () => {
      if (hash) {
        const element = document.getElementById(removeHashCharacter(hash));

        if (element) {
          element.scrollIntoView({
            behavior: isMobileDevice() ? "auto" : "auto",
            block: "start",
            inline: "start",
          });
        }
      }
    };

    // Check if document is fully loaded, if not wait for window.onload
    if (document.readyState === "complete") {
      scrollToElement();
    } else {
      window.onload = scrollToElement;
    }
  }, [location]);

  return null;
};

export default ScrollToHashElement;
