import React from "react";
import "./Commercial.scss";
import ruko from "../../media/Ruko/index.js";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faToilet,
  faAlignJustify,
  faMaximize,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Gbp from "../../media/Ruko/gbp.webp";
const Commercial = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const comercial = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Westfield)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  const gbp = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(GBP)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  return (
    <div id="ruko" className="commercial-container">
      <div className="commercial-title">Commercial Area</div>
      <div className="commercial-card-container">
        <div className="commercial-card">
          <div className="card">
            <Slider {...settings}>
              {ruko.map((lt7, index) => (
                <img
                  className="card-image"
                  key={index}
                  src={lt7}
                  alt={`Launching ${index + 1}`}
                ></img>
              ))}
            </Slider>
            <div className="content-card">
              <div className="card-title">RUKO NEW WESTFIELD</div>
              <div className="card-price">
                <div className="judul-pt">START FROM</div>
                <div className="pricetotal">11</div>
                <div className="ket-pt">JUTA/BULAN</div>
              </div>
              <div className="card-dp">TANPA DP</div>
              <div className="card-icon">
                <FontAwesomeIcon icon={faMaximize} />
                <span>: 50m² </span>
                <FontAwesomeIcon icon={faBuilding} />
                <span>: 106m² </span>
                <FontAwesomeIcon icon={faAlignJustify} />
                <span>: 3+1 </span>
                <FontAwesomeIcon icon={faToilet} />
                <span>: 3 </span>
              </div>
              <div className="card-button">
                <button onClick={comercial} className="button">
                  {" "}
                  <FontAwesomeIcon size="lg" icon={faWhatsapp} />
                  &nbsp;Whatsapp
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="commercial-card">
          <div className="card">
            <img className="card-image" src={Gbp} alt={ruko}></img>
            <div className="content-card">
              <div className="card-title">Grand Business Park</div>
              <div className="card-price">
                <div className="judul-pt">START FROM</div>
                <div className="pricetotal">19</div>
                <div className="ket-pt">JUTA/BULAN</div>
              </div>
              <div className="card-dp">TANPA DP</div>
              <div className="card-icon">
                <FontAwesomeIcon icon={faMaximize} />
                <span>: 265m² </span>
                <FontAwesomeIcon icon={faBuilding} />
                <span>: 90m² </span>
                <FontAwesomeIcon icon={faAlignJustify} />
                <span>: 4 </span>
                <FontAwesomeIcon icon={faToilet} />
                <span>: 4 </span>
              </div>
              <div className="card-button">
                <button onClick={gbp} className="button">
                  {" "}
                  <FontAwesomeIcon size="lg" icon={faWhatsapp} />
                  &nbsp;Whatsapp
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Commercial;
