import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-closing">
        <div className="footer-title">Marketing Gallery</div>
        <div className="footer-subtitle">GRAND WISATA</div>
        <div className="footer-alamat">
          Jl. Celebration Boulevard, Lambangjaya, Kec. Tambun Sel., Kabupaten
          Bekasi, Jawa Barat 17510
        </div>
        <div className="footer-contact">Contact Us : +62895417144022</div>
        <Link to="/privacy-policy">
          <div className="footer-contact">Privacy Policy</div>
        </Link>
      </div>
      <div className="footer">
        <div className="footer-subtitle2">GRAND WISATA | 2023 |</div>
        <hr />
        <div className="sub">POWERED BY : LINKTOWN</div>
      </div>
    </div>
  );
};

export default Footer;
