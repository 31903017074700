import React, { useState } from "react";
import "./Navbar.scss";
import { HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiamond, faGem } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="navbarcontainer">
      <div className="navbar">
        <div className="navbarlogo">GRAND WISATA</div>
        <div className="navbaritem">
          <ul className={`item ${isMobileMenuOpen ? "active" : ""}`}>
            <li>
              <HashLink smooth to="/#promo">
                Promo
              </HashLink>
            </li>
            <li>
              <HashLink to="/#newlaunch" smooth>
                <FontAwesomeIcon icon={faGem} />
                &nbsp; NEW LAUNCHING
              </HashLink>
            </li>
            <li>
              <HashLink to="/#produk" smooth>
                Rumah
              </HashLink>
            </li>
            <li>
              <HashLink to="/#kavling" smooth>
                Kavling
              </HashLink>
            </li>
            <li>
              <HashLink to="/#ruko" smooth>
                Ruko
              </HashLink>
            </li>
            <li>
              <HashLink to="/#lokasi" smooth>
                Lokasi
              </HashLink>
            </li>
          </ul>
        </div>
        <div className="navbar-burger">
          <button
            className={`burger-button ${isMobileMenuOpen ? "active" : ""}`}
            onClick={toggleMobileMenu}
          >
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
