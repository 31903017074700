import t1 from "./s2.webp";
import t2 from "./10-2.webp";
import t3 from "./11-1.webp";
import t4 from "./12-1.webp";
import t5 from "./14-1.webp";
import t6 from "./16-1.webp";
import t7 from "./17.webp";
import t8 from "./18.webp";

const tanamas = [t1, t2, t3, t4, t5, t6, t7, t8];

export default tanamas;
