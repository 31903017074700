import React from "react";
import "./Cluster.scss";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faBed,
  faShower,
  faArrowsUpDownLeftRight,
} from "@fortawesome/free-solid-svg-icons";
import tanamas from "../../media/Tanamas/index";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import zliving from "../../media/Zliving/index";
import water from "../../media/Water/index";
import Card2 from "./card2/card2.jsx";

const Cluster = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const Tanamas = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Tanamas)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  const Zliving = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Z-Living)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  const Water = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Water%20Terrace)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  return (
    <div id="produk" className="cluster-container">
      <div className="cluster-title">Another Cluster at Grand Wisata</div>
      <div className="cluster-card-container">
        <div className="cluster-card">
          <Slider {...settings}>
            {tanamas.map((tanamas, index) => (
              <img
                className="cluster-image"
                key={index}
                src={tanamas}
                alt={`Launching ${index + 1}`}
              />
            ))}
          </Slider>
          <div className="cluster-card-title">
            <div className="cluster-name">
              <div className="cluster-name-sub">Tanamas</div>
              <div className="cluster-name-sub2">Tanpa DP</div>
            </div>
            <div className="cluster-price">
              <div className="cluster-price-sub">6</div>
              <div className="cluster-price-sub2">Juta / Bulan</div>
            </div>
          </div>
          <hr className="card-separator" />
          <div className="cluster-icon">
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
            <span>&nbsp;: 84m²&nbsp;&nbsp;</span>
            <FontAwesomeIcon icon={faHouse} />
            <span>&nbsp;: 73m²&nbsp;&nbsp;</span>
            <FontAwesomeIcon icon={faBed} />
            <span>&nbsp;: 4&nbsp;&nbsp;</span>
            <FontAwesomeIcon icon={faShower} />
            <span>&nbsp;: 2&nbsp;&nbsp;</span>
          </div>
          <div className="cluster-button">
            <button onClick={Tanamas} className="cluster-whatsapp">
              <FontAwesomeIcon size="lg" icon={faWhatsapp} />
              &nbsp;Whatsapp
            </button>
          </div>
        </div>
        <div className="cluster-card">
          <Slider {...settings}>
            {zliving.map((tanamas, index) => (
              <img
                className="cluster-image"
                key={index}
                src={tanamas}
                alt={`Launching ${index + 1}`}
              />
            ))}
          </Slider>
          <div className="cluster-card-title">
            <div className="cluster-name">
              <div className="cluster-name-sub">Z-living</div>
              <div className="cluster-name-sub2">Tanpa DP</div>
            </div>
            <div className="cluster-price">
              <div className="cluster-price-sub">7</div>
              <div className="cluster-price-sub2">Juta / Bulan</div>
            </div>
          </div>
          <hr className="card-separator" />
          <div className="cluster-icon">
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
            <span>&nbsp;: 88m²&nbsp;&nbsp;</span>
            <FontAwesomeIcon icon={faHouse} />
            <span>&nbsp;: 88m²&nbsp;&nbsp;</span>
            <FontAwesomeIcon icon={faBed} />
            <span>&nbsp;: 4&nbsp;&nbsp;</span>
            <FontAwesomeIcon icon={faShower} />
            <span>&nbsp;: 4&nbsp;&nbsp;</span>
          </div>
          <div className="cluster-button">
            <button onClick={Zliving} className="cluster-whatsapp">
              <FontAwesomeIcon size="lg" icon={faWhatsapp} />
              &nbsp;Whatsapp
            </button>
          </div>
        </div>
        <div className="cluster-card">
          <Slider {...settings}>
            {water.map((tanamas, index) => (
              <img
                className="cluster-image"
                key={index}
                src={tanamas}
                alt={`Launching ${index + 1}`}
              />
            ))}
          </Slider>
          <div className="cluster-card-title">
            <div className="cluster-name">
              <div className="cluster-name-sub">Water Terrace</div>
              <div className="cluster-name-sub2">Tanpa DP</div>
            </div>
            <div className="cluster-price">
              <div className="cluster-price-sub">16</div>
              <div className="cluster-price-sub2">Juta / Bulan</div>
            </div>
          </div>
          <hr className="card-separator" />
          <div className="cluster-icon">
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
            <span>&nbsp;: 160m²&nbsp;&nbsp;</span>
            <FontAwesomeIcon icon={faHouse} />
            <span>&nbsp;: 143m²&nbsp;&nbsp;</span>
            <FontAwesomeIcon icon={faBed} />
            <span>&nbsp;: 3+1&nbsp;&nbsp;</span>
            <FontAwesomeIcon icon={faShower} />
            <span>&nbsp;: 4+1&nbsp;&nbsp;</span>
          </div>
          <div className="cluster-button">
            <button onClick={Water} className="cluster-whatsapp">
              <FontAwesomeIcon size="lg" icon={faWhatsapp} />
              &nbsp;Whatsapp
            </button>
          </div>
        </div>
      </div>
      <Card2 />
    </div>
  );
};

export default Cluster;
