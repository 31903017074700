import O81 from "./95.webp";
import O82 from "./96.webp";
import O83 from "./97.webp";
import O84 from "./98.webp";
import O85 from "./99.webp";
import O86 from "./100.webp";
import O87 from "./101.webp";
import O818 from "./102.webp";

const O8 = [O81, O82, O83, O84, O85, O86, O87, O818];

export default O8;
