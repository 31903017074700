import React from "react";
import "./Lokasi.scss";
import map from "../../media/Untitled.webp";

const Lokasi = () => {
  return (
    <div className="lokasi-container">
      <div className="lokasi-inner">
        <div id="lokasi" className="lokasi-title">
          LOKASI
        </div>
        <div className="lokasi-map">
          <img className="image-map" src={map} alt="Grand Wisata Bekasi" />
        </div>
        <div className="lokasi-list">
          <li>Living World Mall</li>
          <li>Go! Wet Waterpark</li>
          <li>Akses Tol Langsung</li>
          <li>SMA Islam Al Azhar 18 Grand Wisata</li>
          <li>Rumah Sakit Eka Hospital</li>
          <li>Rumah Sakit Hermina</li>
          <li>Grand Wisata</li>
          <li>Rumah Sakit Permata</li>
          <li>Rumah Sakit Mitra Keluarga</li>
          <li>Sekolah Kristen IPEKA Grand Wisata</li>
          <li>Pasar Modern</li>
          <li>Wisata Kuliner</li>
        </div>
      </div>
    </div>
  );
};

export default Lokasi;
