import React from "react";
import "./Grandjudul.scss";

const Grandjudul = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20()%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  return (
    <div id="about" className="grand-container">
      <div className="grand-center">
        <div className="grand-title">WELCOME TO OUR WEBSITE</div>
        <div className="grand-subtitle">Grand Wisata Bekasi</div>
        <div className="grand-description">
          Sinar Mas Land, perusahaan properti terkemuka di Asia Tenggara,
          membangun Grand Wisata Bekasi, sebuah kawasan hunian modern. Konsep
          Live, Play, and Work yang ditawarkan oleh Grand Wisata Bekasi
          memadukan kebutuhan akan tempat tinggal, rekreasi, dan tempat kerja
          dalam satu lokasi. Karena menawarkan semua fasilitas yang diperlukan
          dalam satu area, Grand Wisata Bekasi juga disebut sebagai kota
          mandiri.
        </div>
        <div className="grand-button">
          <button onClick={handleConsultationClick} className="button">
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default Grandjudul;
