import imagesv from "../../media/section1/vicente8 (1).webp";
import imagesk from "../../media/section1/kaia (0).webp";
import images1 from "../../media/section1/s1.webp";
import images2 from "../../media/section1/s2.webp";
import images3 from "../../media/section1/s3.webp";
import images4 from "../../media/section1/s4.webp";
import images5 from "../../media/section1/s5.webp";
import images6 from "../../media/section1/s6.webp";

const images = [
  imagesv,
  images1,
  images2,
  images3,
  imagesk,
  images4,
  images5,
  images6,
];

export default images;
