import React from "react";
import "./card2.scss";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faBed,
  faShower,
  faArrowsUpDownLeftRight,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import o8images from "../../../media/o8/index.js";
import Cherryville from "../../../media/cherryville/index.js";
import gardenloftimages from "../../../media/gardenloft/index.js";
import grandlemonade6 from "../../../media/grandlemonade/index.js";

const card2 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const o8 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(o8)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  const grandlemonade = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(grandlemonade)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  const gardenloft = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(gardenloft)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  const cherryville = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Cherryville)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="cluster-card-container2">
      <div className="cluster-card">
        <Slider {...settings}>
          {o8images.map((tanamas, index) => (
            <img
              className="cluster-image"
              key={index}
              src={tanamas}
              alt={`Launching ${index + 1}`}
            />
          ))}
        </Slider>
        <div className="cluster-card-title">
          <div className="cluster-name">
            <div className="cluster-name-sub">O8</div>
            <div className="cluster-name-sub2">Tanpa DP</div>
          </div>
          <div className="cluster-price">
            <div className="cluster-price-sub">7</div>
            <div className="cluster-price-sub2">Juta / Bulan</div>
          </div>
        </div>
        <hr className="card-separator" />
        <div className="cluster-icon">
          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          <span>&nbsp;: 66m²&nbsp;&nbsp;</span>
          <FontAwesomeIcon icon={faHouse} />
          <span>&nbsp;: 65m²&nbsp;&nbsp;</span>
          <FontAwesomeIcon icon={faBed} />
          <span>&nbsp;: 3&nbsp;&nbsp;</span>
          <FontAwesomeIcon icon={faShower} />
          <span>&nbsp;: 2&nbsp;&nbsp;</span>
        </div>
        <div className="cluster-button">
          <button onClick={o8} className="cluster-whatsapp">
            <FontAwesomeIcon size="lg" icon={faWhatsapp} />
            &nbsp;Whatsapp
          </button>
        </div>
      </div>
      <div className="cluster-card">
        <Slider {...settings}>
          {grandlemonade6.map((tanamas, index) => (
            <img
              className="cluster-image"
              key={index}
              src={tanamas}
              alt={`Launching ${index + 1}`}
            />
          ))}
        </Slider>
        <div className="cluster-card-title">
          <div className="cluster-name">
            <div className="cluster-name-sub">Grand Lemonade</div>
            <div className="cluster-name-sub2">Tanpa DP</div>
          </div>
          <div className="cluster-price">
            <div className="cluster-price-sub">7</div>
            <div className="cluster-price-sub2">Juta / Bulan</div>
          </div>
        </div>
        <hr className="card-separator" />
        <div className="cluster-icon">
          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          <span>&nbsp;: 90m²&nbsp;&nbsp;</span>
          <FontAwesomeIcon icon={faHouse} />
          <span>&nbsp;: 66m²&nbsp;&nbsp;</span>
          <FontAwesomeIcon icon={faBed} />
          <span>&nbsp;: 4&nbsp;&nbsp;</span>
          <FontAwesomeIcon icon={faShower} />
          <span>&nbsp;: 2&nbsp;&nbsp;</span>
        </div>
        <div className="cluster-button">
          <button onClick={grandlemonade} className="cluster-whatsapp">
            <FontAwesomeIcon size="lg" icon={faWhatsapp} />
            &nbsp;Whatsapp
          </button>
        </div>
      </div>
      <div className="cluster-card">
        <Slider {...settings}>
          {gardenloftimages.map((tanamas, index) => (
            <img
              className="cluster-image"
              key={index}
              src={tanamas}
              alt={`Launching ${index + 1}`}
            />
          ))}
        </Slider>
        <div className="cluster-card-title">
          <div className="cluster-name">
            <div className="cluster-name-sub">Garden Loft</div>
            <div className="cluster-name-sub2">Tanpa DP</div>
          </div>
          <div className="cluster-price">
            <div className="cluster-price-sub">9</div>
            <div className="cluster-price-sub2">Juta / Bulan</div>
          </div>
        </div>
        <hr className="card-separator" />
        <div className="cluster-icon">
          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          <span>&nbsp;: 110m²&nbsp;&nbsp;</span>
          <FontAwesomeIcon icon={faHouse} />
          <span>&nbsp;: 101m²&nbsp;&nbsp;</span>
          <FontAwesomeIcon icon={faBed} />
          <span>&nbsp;: 4+1&nbsp;&nbsp;</span>
          <FontAwesomeIcon icon={faShower} />
          <span>&nbsp;: 3+1&nbsp;&nbsp;</span>
        </div>
        <div className="cluster-button">
          <button onClick={gardenloft} className="cluster-whatsapp">
            <FontAwesomeIcon size="lg" icon={faWhatsapp} />
            &nbsp;Whatsapp
          </button>
        </div>
      </div>
      <div className="cluster-card">
        <Slider {...settings}>
          {Cherryville.map((tanamas, index) => (
            <img
              className="cluster-image"
              key={index}
              src={tanamas}
              alt={`Launching ${index + 1}`}
            />
          ))}
        </Slider>
        <div className="cluster-card-title">
          <div className="cluster-name">
            <div className="cluster-name-sub">Cherry Ville</div>
            <div className="cluster-name-sub2">Tanpa DP</div>
          </div>
          <div className="cluster-price">
            <div className="cluster-price-sub">10</div>
            <div className="cluster-price-sub2">Juta / Bulan</div>
          </div>
        </div>
        <hr className="card-separator" />
        <div className="cluster-icon">
          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          <span>&nbsp;: 128m²&nbsp;&nbsp;</span>
          <FontAwesomeIcon icon={faHouse} />
          <span>&nbsp;: 108m²&nbsp;&nbsp;</span>
          <FontAwesomeIcon icon={faBed} />
          <span>&nbsp;: 5&nbsp;&nbsp;</span>
          <FontAwesomeIcon icon={faShower} />
          <span>&nbsp;: 5&nbsp;&nbsp;</span>
        </div>
        <div className="cluster-button">
          <button onClick={cherryville} className="cluster-whatsapp">
            <FontAwesomeIcon size="lg" icon={faWhatsapp} />
            &nbsp;Whatsapp
          </button>
        </div>
      </div>
    </div>
  );
};

export default card2;
