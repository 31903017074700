import Vicente81 from "./vicente8 (1).webp";
import Vicente82 from "./vicente8 (2).webp";
import Vicente83 from "./vicente8 (3).webp";
import Vicente84 from "./vicente8 (4).webp";
import Vicente85 from "./vicente8 (5).webp";
import Vicente86 from "./vicente8 (6).webp";
import Vicente87 from "./vicente8 (7).webp";
import Vicente88 from "./vicente8 (8).webp";
import Vicente89 from "./vicente8 (9).webp";
import Vicente810 from "./vicente8 (10).webp";
import Vicente811 from "./vicente8 (11).webp";
import Vicente812 from "./vicente8 (12).webp";
import Vicente813 from "./vicente8 (13).webp";

const vicente8 = [
  Vicente81,
  Vicente82,
  Vicente83,
  Vicente84,
  Vicente85,
  Vicente86,
  Vicente87,
  Vicente88,
  Vicente89,
  Vicente810,
  Vicente811,
  Vicente812,
  Vicente813,
];

export default vicente8;
