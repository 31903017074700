import Vicente61 from "./vicente6 (1).webp";
import Vicente62 from "./vicente6 (2).webp";
import Vicente63 from "./vicente6 (3).webp";
import Vicente64 from "./vicente6 (4).webp";
import Vicente65 from "./vicente6 (5).webp";
import Vicente66 from "./vicente6 (6).webp";
import Vicente67 from "./vicente6 (7).webp";
import Vicente68 from "./vicente6 (8).webp";
import Vicente69 from "./vicente6 (9).webp";
import Vicente610 from "./vicente6 (10).webp";
import Vicente611 from "./vicente6 (11).webp";

const vicente6 = [
  Vicente61,
  Vicente62,
  Vicente63,
  Vicente64,
  Vicente65,
  Vicente66,
  Vicente67,
  Vicente68,
  Vicente69,
  Vicente610,
  Vicente611,
];

export default vicente6;
