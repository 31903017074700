import Gardenloft1 from "./gardenloft (1).webp";
import Gardenloft2 from "./gardenloft (2).webp";
import Gardenloft3 from "./gardenloft (3).webp";
import Gardenloft4 from "./gardenloft (4).webp";
import Gardenloft5 from "./gardenloft (5).webp";
import Gardenloft6 from "./gardenloft (6).webp";
import Gardenloft7 from "./gardenloft (7).webp";

const gardenloft = [
  Gardenloft1,
  Gardenloft2,
  Gardenloft3,
  Gardenloft4,
  Gardenloft5,
  Gardenloft6,
  Gardenloft7,
];

export default gardenloft;
