import React from "react";
import "./Grandwiss.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import images from "./index";

const Grandwiss = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Breakpoint for medium screens (adjust as needed)
        settings: {
          slidesToShow: 2, // Change number of slides for medium screens
        },
      },
      {
        breakpoint: 768, // Breakpoint for small screens (adjust as needed)
        settings: {
          slidesToShow: 1, // Change number of slides for small screens
        },
      },
    ],
  };

  return (
    <div className="grandwiss-container">
      <div className="grandwiss-carousel">
        <div className="grandwiss-img">
          <Slider {...settings}>
            {images.map((image, index) => (
              <img
                className="kartugambar"
                key={index}
                src={image}
                alt={`Astha ${index + 1}`}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Grandwiss;
