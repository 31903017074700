import Grandlemonade1 from "./90.webp";
import Grandlemonade2 from "./91.webp";
import Grandlemonade3 from "./92.webp";
import Grandlemonade4 from "./93.webp";
import Grandlemonade5 from "./94.webp";
import Grandlemonade6 from "./95.webp";

const grandlemonade6 = [
  Grandlemonade1,
  Grandlemonade2,
  Grandlemonade3,
  Grandlemonade4,
  Grandlemonade5,
  Grandlemonade6,
];

export default grandlemonade6;
