import s1 from "./s1.webp";
import s2 from "./s2.webp";
import s3 from "./s3.webp";
import s4 from "./s4.webp";
import s5 from "./s5.webp";
import s6 from "./s6.webp";
import s7 from "./s7.webp";
import s8 from "./s8.webp";

const zliving = [s1, s2, s3, s4, s5, s6, s7, s8];

export default zliving;
