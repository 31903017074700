import React from "react";
import "./Promo.scss";
import imgPromo from "../../media/promo/newpromo/design hardsel 34 web (juli-present)) (4).png";

const Promo = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Promo)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  return (
    <div>
      <div id="promo" className="containerPenawaran">
        <div className="containergambar">
          <div className="carousellogo">
            <img src={imgPromo} alt="" />
          </div>
        </div>
        <div className="containercontent">
          <button className="buttonpenawaran" onClick={handleConsultationClick}>
            Request Consultation
          </button>
        </div>
      </div>
    </div>
  );
};

export default Promo;
