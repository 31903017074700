import React from "react";
import "./vicente.scss";
import Slider from "react-slick";
import vicente6 from "../../../media/vicente/vicente6/vicente6";
import vicente7 from "../../../media/vicente/vicente7/vicente7";
import vicente8 from "../../../media/vicente/vicente8/vicente8";
import vicente10 from "../../../media/vicente/vicente10/vicente10";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faBed,
  faShower,
  faMaximize,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const vicente = () => {
  const v6 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Vicente6)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  const v7 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(vicente7)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  const v8 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(vicente8)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  const v10 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(vicente10)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div id="vicente" className="container-vicente">
      <div className="card">
        <Slider {...settings}>
          {vicente6.map((vicente6, index) => (
            <img
              className="card-image"
              key={index}
              src={vicente6}
              alt={`Launching ${index + 1}`}
            ></img>
          ))}
        </Slider>
        <div className="content-card">
          <div className="card-title">VICENTE 6</div>
          <div className="card-price">
            <div className="judul-pt">START FROM</div>
            <div className="pricetotal">7</div>
            <div className="ket-pt">JUTA/BULAN</div>
          </div>
          <div className="card-dp">TANPA DP</div>
          <div className="card-icon">
            <FontAwesomeIcon icon={faMaximize} />
            <span>: 72m² </span>
            <FontAwesomeIcon icon={faHouse} />
            <span>: 87m² </span>
            <FontAwesomeIcon icon={faBed} />
            <span>: 3 </span>
            <FontAwesomeIcon icon={faShower} />
            <span>: 2 </span>
          </div>
          <div className="card-button">
            <button className="button" onClick={v6}>
              <FontAwesomeIcon size="lg" icon={faWhatsapp} />
              &nbsp;Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div className="card">
        <Slider {...settings}>
          {vicente7.map((vicente8, index) => (
            <img
              className="card-image"
              key={index}
              src={vicente8}
              alt={`Launching ${index + 1}`}
            ></img>
          ))}
        </Slider>
        <div className="content-card">
          <div className="card-title">VICENTE 7</div>
          <div className="card-price">
            <div className="judul-pt">START FROM</div>
            <div className="pricetotal">8</div>
            <div className="ket-pt">JUTA/BULAN</div>
          </div>
          <div className="card-dp">TANPA DP</div>
          <div className="card-icon">
            <FontAwesomeIcon icon={faMaximize} />
            <span>: 126m² </span>
            <FontAwesomeIcon icon={faHouse} />
            <span>: 106m² </span>
            <FontAwesomeIcon icon={faBed} />
            <span>: 3+1 </span>
            <FontAwesomeIcon icon={faShower} />
            <span>: 3+1 </span>
          </div>
          <div className="card-button">
            <button className="button" onClick={v7}>
              <FontAwesomeIcon size="lg" icon={faWhatsapp} />
              &nbsp;Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div className="card">
        <Slider {...settings}>
          {vicente8.map((vicente9, index) => (
            <img
              className="card-image"
              key={index}
              src={vicente9}
              alt={`Launching ${index + 1}`}
            ></img>
          ))}
        </Slider>
        <div className="content-card">
          <div className="card-title">VICENTE 8</div>
          <div className="card-price">
            <div className="judul-pt">START FROM</div>
            <div className="pricetotal">10</div>
            <div className="ket-pt">JUTA/BULAN</div>
          </div>
          <div className="card-dp">TANPA DP</div>
          <div className="card-icon">
            <FontAwesomeIcon icon={faMaximize} />
            <span>: 120m² </span>
            <FontAwesomeIcon icon={faHouse} />
            <span>: 128m² </span>
            <FontAwesomeIcon icon={faBed} />
            <span>: 4+1 </span>
            <FontAwesomeIcon icon={faShower} />
            <span>: 3+1 </span>
          </div>
          <div className="card-button">
            <button className="button" onClick={v8}>
              <FontAwesomeIcon size="lg" icon={faWhatsapp} />
              &nbsp;Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div className="card">
        <Slider {...settings}>
          {vicente10.map((vicente8, index) => (
            <img
              className="card-image"
              key={index}
              src={vicente8}
              alt={`Launching ${index + 1}`}
            ></img>
          ))}
        </Slider>
        <div className="content-card">
          <div className="card-title">VICENTE 10</div>
          <div className="card-price">
            <div className="judul-pt">START FROM</div>
            <div className="pricetotal">13</div>
            <div className="ket-pt">JUTA/BULAN</div>
          </div>
          <div className="card-dp">TANPA DP</div>
          <div className="card-icon">
            <FontAwesomeIcon icon={faMaximize} />
            <span>: 140m² </span>
            <FontAwesomeIcon icon={faHouse} />
            <span>: 154m² </span>
            <FontAwesomeIcon icon={faBed} />
            <span>: 4+1 </span>
            <FontAwesomeIcon icon={faShower} />
            <span>: 4+1 </span>
          </div>
          <div className="card-button">
            <button className="button" onClick={v10}>
              <FontAwesomeIcon size="lg" icon={faWhatsapp} />
              &nbsp;Whatsapp
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default vicente;
