import Cherryville1 from "./cherryville (1).webp";
import Cherryville2 from "./cherryville (2).webp";
import Cherryville3 from "./cherryville (3).webp";
import Cherryville4 from "./cherryville (4).webp";
import Cherryville5 from "./cherryville (5).webp";
import Cherryville6 from "./cherryville (6).webp";
import Cherryville7 from "./cherryville (7).webp";
import Cherryville8 from "./cherryville (8).webp";
import Cherryville9 from "./cherryville (9).webp";
import Cherryville10 from "./cherryville (10).webp";
import Cherryville11 from "./cherryville (11).webp";
import Cherryville12 from "./cherryville (12).webp";
import Cherryville13 from "./cherryville (13).webp";
import Cherryville14 from "./cherryville (14).webp";
import Cherryville15 from "./cherryville (15).webp";
import Cherryville16 from "./cherryville (16).webp";

const Cherryville = [
  Cherryville1,
  Cherryville2,
  Cherryville3,
  Cherryville4,
  Cherryville5,
  Cherryville6,
  Cherryville7,
  Cherryville8,
  Cherryville9,
  Cherryville10,
  Cherryville11,
  Cherryville12,
  Cherryville13,
  Cherryville14,
  Cherryville15,
  Cherryville16,
];

export default Cherryville;
